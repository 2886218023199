<template>
  <div class="app-container">
    <div class="top_btns clearfix">
      <div class="left_search fl">
        <el-input placeholder="公司名称/联系人/手机号" v-model="listQuery.name" style="width: 140px;" size="small" />
        <el-button class="filter-item" type="primary" @click="getList" size="small" plain>搜索</el-button>
        <el-select size="small" v-model="listQuery.status" @change="getList" filterable placeholder="请选择" style="width: 82px;margin-right:10px">
          <el-option key="0" label="全部" :value="0"></el-option>
          <el-option key="1" label="正常使用" :value="1"></el-option>
          <el-option key="2" label="试用" :value="2"></el-option>
          <el-option key="3" label="停用" :value="3"></el-option>
          <el-option key="4" label="活动账号" :value="4"></el-option>
        </el-select>
        <el-date-picker
          style="width:100px;"
          size="small"
          value-format="YYYY-MM"
          v-model="listQuery.startPeriod"
          type="month"
          placeholder="到期时间起"
          @change="getList"
        >
        </el-date-picker>
        -
        <el-date-picker
          style="width:100px;"
          size="small"
          value-format="YYYY-MM"
          v-model="listQuery.endPeriod"
          type="month"
          placeholder="到期时间止"
          @change="getList"
        >
        </el-date-picker>
      </div>
      <div class="right_btns fr">
        <el-button @click="handleCreate()" type="primary" size="small">新增</el-button>
        <el-button @click="checkTotal()" type="primary" size="small">统计</el-button>
      </div>
      <div style="float:right">
        <span style="font-size: 14px;margin-right: 10px;">集团账套总数:<span style="color: #17a2b8;">{{ maxCount }}</span>，已使用账套数:<span style="color: green;">{{ usedCount }}</span>，剩余账套数:<span style="color: red;">{{ maxCount - usedCount }}</span></span>
      </div>
    </div>

    <el-table :height="contentStyleObj" :data="list" border fit highlight-current-row style="width: 100%;" @selection-change="handleSelectionChange" @sort-change="sortChange" v-loading="loading">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="序号" align="center" width="55" type="index">
      </el-table-column>
      <el-table-column label="公司名" align="center" min-width="155" prop="name">
      </el-table-column>
      <el-table-column label="使用状态" align="center" width="90">
        <template #default="scope">
          <span v-if="scope.row.zcsy == 1" style="color:green">正常使用</span>
          <span v-else style="color:orange">非正常</span>
        </template>
      </el-table-column>
      <el-table-column label="负责人" align="center" width="70" prop="manager">
      </el-table-column>
      <el-table-column label="负责人电话" align="center" width="120" prop="managerTel">
      </el-table-column>
      <el-table-column label="创建人" align="center" width="100" prop="createName">
      </el-table-column>
      <el-table-column label="是否试用用户" align="center" width="110">
        <template #default="scope">
          <!-- <span v-if="scope.row.isTrial == 0" style="color:green">正常使用</span>
          <span v-else style="color:orange">试用</span> -->
          <div v-if="scope.row.isTrial == 0">
              <span v-if="scope.row.status == 1" style="color:green">正常使用</span>
              <span v-else style="color:red">已停用</span>
            </div>
            <div v-if="scope.row.isTrial == 1">
              <span v-if="scope.row.status == 1" style="color:blue">试用</span>
              <span v-else style="color:red">已停用</span>
            </div>
            <div v-if="scope.row.isTrial == 2">
              <span v-if="scope.row.status == 1" style="color:orange">活动</span>
              <span v-else style="color:red">已停用</span>
            </div>
        </template>
      </el-table-column>
      <el-table-column label="测试企业" align="center" width="85">
        <template #default="scope">
          <span v-if="scope.row.isCs == 1" style="color:red">是</span>
          <span v-else style="color:green">否</span>
        </template>
      </el-table-column>
      <el-table-column label="账套个数" align="center" width="85" prop="count">
      </el-table-column>
      <el-table-column label="已使用" align="center" width="70" prop="used">
      </el-table-column>
      <el-table-column label="账套启用日期" align="center" width="110">
        <template #default="scope">
            <!-- <span v-else>暂未设置</span> -->
            <span v-if="!scope.row.countTime.match('0001')">{{$parseTime(scope.row.countTime, "{y}-{m}-{d}")}}</span>
            <span v-else>暂未设置</span>
        </template>
      </el-table-column>
      <el-table-column label="账套到期日期" align="center" width="110">
        <template #default="scope">
            <span v-if="scope.row.countUseTime">{{scope.row.countUseTime}}</span>
            <span v-else>&nbsp;</span>
        </template>
      </el-table-column>
      <el-table-column label="所属税务总库" align="center" width="120" prop="districtName">
      </el-table-column>
      <el-table-column label="备注" align="center" width="65" prop="remark">
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="360">
          <template #default="scope">
            <el-button type="primary" size="small" @click="handleUpdate(scope.row)" plain>编辑</el-button>
            <el-button size="small" type="success" @click="handleAdmin(scope.row)" plain>管理员账号</el-button>
            <el-button size="small" type="danger" @click="handleDelete(scope.row)" plain>删除</el-button>
            <el-button type="danger" v-if="scope.row.status == 1" size="small" class="tablebutton" @click="stopStatus(scope.row)" plain>停用</el-button>
            <el-button type="success" v-else size="small" class="tablebutton" @click="startStatus(scope.row)" plain>启用</el-button>
            <!-- <el-button size="small" type="warning" @click="bind(scope.row)" plain>绑定</el-button> -->

          </template>
        </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>

  </div>

  <!-- 新增编辑的弹窗 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close :title="textMap[dialogStatus]" v-model="dialogFormVisible"  class="button_bg" width="700px">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="140px" >
        <div class="grid-content bg-purple">
          <el-form-item label="公司名称:" prop="name" size="small">
            <el-input v-model.trim="temp.name" style="width:95%"/>
          </el-form-item>
          <el-form-item label="负责人:" prop="manager" size="small">
            <el-input v-model="temp.manager" style="width:95%"/>
          </el-form-item>

          <el-form-item label="负责人手机号:" prop="managerTel" size="small">
            <el-input v-model="temp.managerTel" style="width:95%"/>
          </el-form-item>

          <el-form-item label="地址:" prop="address" size="small">
            <el-input v-model="temp.address" style="width:95%"/>
          </el-form-item>

          <el-form-item label="用户类型:" prop="isTrial">
            <el-radio-group v-model="temp.isTrial">
              <el-radio :label="0">正常</el-radio>
              <el-radio :label="1">试用</el-radio>
              <el-radio :label="2">活动账号</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="测试企业:" prop="simple">
            <el-radio-group v-model="temp.isCs">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标记:" prop="zcsy">
            <el-radio-group v-model="temp.zcsy">
              <el-radio :label="1">正常使用</el-radio>
              <el-radio :label="0">非正常</el-radio>
            </el-radio-group>
          </el-form-item>

          <!-- <el-form-item label="简易版:" prop="simple">
            <el-radio-group v-model="temp.simple">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item> -->

          <el-form-item label="所属税务总局:" prop="districtCode" size="small">
            <selectcity v-model:citys="temp.districtCode"></selectcity>
          </el-form-item>

          <el-form-item label="账套个数:" prop="count">
            <el-input style="width:220px"  type="number" v-model.number="temp.count" size="small"/>
          </el-form-item>
          <!-- <el-form-item label="启用时间:" prop="countTime" style="width:98%;">
            <el-date-picker v-model="temp.countTime" type="date" placeholder="选择日期" @change="QYSJ" size="small">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="到期时间:" style="width:98%;">
            <el-date-picker v-model="temp.countUseTime" value-format="YYYY-MM-DD" type="date" placeholder="选择日期" size="small">
            </el-date-picker>
          </el-form-item> -->

          <el-form-item label="备注:" prop="remark" size="small">
            <el-input v-model="temp.remark" style="width:95%" type="textarea"/>
          </el-form-item>
            
        </div>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()" size="small">确定</el-button>
      </span>
    </template>
    </el-dialog>

    <!-- 设置管理员弹窗 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="设置管理员账号" v-model="dialogAdminFormVisible"  class="button_bg" width="500px">
      <el-form :model="adminTemp" label-position="right" label-width="100px" >
          <el-form-item label="姓名:" prop="name" size="small">
            <el-input v-model="adminTemp.cnName" style="width:85%"/>
          </el-form-item>

          <el-form-item label="手机号:" prop="manager" size="small">
            <el-input disabled v-model="adminTemp.mobile" style="width:85%"/>
          </el-form-item>

          <el-form-item label="用户名:" prop="managerTel" size="small">
            <el-input v-model="adminTemp.userName" style="width:85%"/>
          </el-form-item>

          <el-form-item label="密码:" prop="address" size="small">
            <el-input disabled v-model="adminTemp.password" style="width:85%"/>
          </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogAdminFormVisible = false" size="small">取消</el-button>
          <el-button type="primary" @click="adminUpdate" size="small">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 设置绑定群聊 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="绑定企业微信" v-model="dialogBindFormVisible"  class="button_bg" width="400px">
      <el-form :model="countsTemp" label-position="right" label-width="100px" >
        <el-form-item label="绑定名称:" prop="count">
          <el-select
            v-model="corpid"
            placeholder="请选择"
            size="small"
            filterable
            clearable
            style="width:80%"
          >
            <el-option
              v-for="item in corpList"
              :key="item.corpid"
              :label="item.corpName"
              :value="item.corpid"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogBindFormVisible = false" size="small">取消</el-button>
          <el-button type="primary" @click="bindSure" size="small">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <orgStatistics ref="orgStatistics" from="group"/>
</template>

<script>
import { groupOrgList ,addGroupOrg ,saveOrg ,delOrg,findOrgAdmin,saveOrgAdmin } from "@/api/org"
import { getChatCompanyList,bindOrg } from '@/api/company'


import selectcity from "@/components/Screening/selectcity";
import orgStatistics from '../platform/components/orgStatistics.vue'

export default {
  name: 'group2',
  components:{
    selectcity,
    orgStatistics
  },
  data() {
    return {
      list:[],
      listQuery: {
        page: 1,
        limit: 20,
        name:'',
        credits: 0,
        status: 0,
        startPeriod:"",
        endPeriod:""
      },
      adminTemp: {
        cnName:"",
        userName: '',
        mobile:'',
        password: '',
      },
      total: 0,
      temp: {
        managerTel: '',
        name: '',
        manager: '',
        districtCode: '',
        address: '',
        logoUrl: '',
        groupId: 0
      },
      dialogFormVisible: false,
      dialogAdminFormVisible: false,
      textMap: {
        update: '修改',
        create: '新增'
      },
      maxCount:0,
      usedCount:0,
      dialogBindFormVisible:false,
      corpList:[],
      corpid:null
    };
  },

  mounted() {
    
  },
  created(){
    this.contentStyleObj= this.$getHeight(290)
    this.getList()
  },

  methods: {
    getList(){
      groupOrgList(this.listQuery).then(res=>{
        if(res.data.msg == "success"){
          this.maxCount = res.data.data.maxSetCount;
          this.usedCount = res.data.data.curUsedCount
          this.list = res.data.data.list
          this.total = res.data.data.total 
        }
      })
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    resetTemp() {
      this.temp = {
        managerTel: '',
        name: '',
        manager: '',
        districtCode: [],
        address: '',
        type:'agent',
        logoUrl: ''
      }
    },
    createData() {
      if(!this.temp.name){
        this.$qzfMessage('请输入公司名称',1)
        return;
      }
      addGroupOrg(this.temp).then(res => {
        if(res.data.msg == 'success'){
          this.getList()
          this.dialogFormVisible = false
          this.$qzfMessage("创建成功")
        } 
      })
    },
    updateData() {
      if(!this.temp.name){
        this.$qzfMessage('请输入公司名称',1)
        return;
      }
      addGroupOrg(this.temp).then(res => {
        if(res.data.msg == 'success'){
          this.getList()
          this.dialogFormVisible = false
          this.$qzfMessage("更新成功")
        }
      })
    },
    stopStatus(row){
      row.status = 3
      saveOrg(row).then(res => {
        if(res.data.msg == 'success'){
          this.getList()
          this.$qzfMessage("停用成功")
        }
      })
    },
    startStatus(row){
      row.status = 1
      saveOrg(row).then(res => {
        if(res.data.msg == 'success'){
          this.getList()
          this.$qzfMessage("启用成功")
        }
      })
    },
    handleDelete(row){
      this.$confirm('你确定要删除该代账公司吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delOrg(row).then(res=>{
          if(res.data.msg == 'success'){
            this.getList()
            this.$qzfMessage("删除成功")
          }
        })
      });
    },
    handleAdmin(row){
      findOrgAdmin({orgId:row.id}).then(res=>{
        if(res.data.msg == "success"){
          this.adminTemp = res.data.data.data
          this.adminTemp.orgId = row.id
          this.adminTemp.isAdmin = 1
        }
      })
      this.dialogAdminFormVisible = true
    },
    adminUpdate(){
      saveOrgAdmin(this.adminTemp).then(res=>{
        if(res.data.msg == 'success'){
          this.dialogAdminFormVisible = false
          this.getList()
          this.$qzfMessage("操作成功")
        }
      })
    },
     //Org绑定corp
     bind(item){
      getChatCompanyList({}).then(res=>{
        if(res.data.msg == 'success'){
          this.corpList = res.data.data.list;
          this.bindId = item.id;
          this.dialogBindFormVisible = true
        }
      })
    },
    bindSure(){
      bindOrg({
       orgId:this.bindId,
       corpid:this.corpid
      }).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage('绑定成功');
          this.dialogBindFormVisible = false;
          this.bindId = null;
          this.corpid = null
        }
      })
    },
    //统计
    checkTotal(){
      this.$refs.orgStatistics.init()
    },
  },
};
</script>

<style scoped lang="scss">
.top_btns {
  margin-bottom: 10px;
  .left_search{
    float: left;
  }
  .right_btns {
    float: right;
    text-align: right;
  }
}
.el-input {
  width: 96%;
}
.item-p{
  border-top: 1px solid #efefef;
}
.item-p:nth-child(1){
  border-top: none;
}
</style>