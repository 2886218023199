<template>
  <div v-if="drawer" class="zz">
    <autoHomeIndex ref="autoHomeIndex" @back="drawer = false" />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import autoHomeIndex from "./autoHomeIndex";
const drawer = ref(true)
</script>

<style scoped lang="scss">
.zz {
  width: 100%;
  height: calc(100% - 50px);
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0);
  z-index: 999;
  overflow-y: auto;
  margin-top: 50px;
}
</style>
